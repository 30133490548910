<template>
    <v-card 
        v-if="!!controlInfo && !!controlInfo.ControlType && (!!controlInfo.InnerControlDate || !!controlInfo.OuterControlDate)"
        class="execution-form-widget" 
        flat
    >
        <v-card-text :class="getClassByDocumentStatus()">

            <div
                v-if="!!controlInfo.InnerControlDate" 
                class="efw-title"
            >
                <span v-if="controlInfo.ControlType == 100">
                    {{$t("Снят_с_контроля")}}
                </span>

                <template v-else>
                    <template>{{$t("Контрольный_срок")}}:</template>
                    <template> {{ controlInfo.InnerControlDate | formattedDate }}, {{ $t(documentStatus.text) }}</template>
                </template>              

            </div>

            <div
                v-if="!!controlInfo.OuterControlDate" 
                class="efw-title"
            >
                <template>{{ $t("Внешний_срок") }}:</template>
                <template v-if="isOuterControlDateToday"> {{ controlInfo.OuterControlDate | formattedDate }} ({{ $t("Сегодня") }})</template>
                <template v-else> {{ controlInfo.OuterControlDate | formattedDate }}</template>
                <template>, {{ controlInfo.OuterControlType }}</template>

            </div>

            <div 
                v-if="!!controlInfo.MainExecuterId"
                class="efw-desc-wrap"
            >
                <div class="efw-description">{{$t("Ответственный_исполнитель")}}:</div>
                <v-workplace-chip :id="controlInfo.MainExecuterId" :name="controlInfo.MainExecuterName" :main="true" />
            </div>

            <div 
                v-if="!!controlInfo.MainExecuterId && configuration == 'C4' && !(isCommon && dsType === 'IncomingDocument')"
                class="efw-desc-wrap"
            >
                <div class="efw-description">{{$t("Фактический_исполнитель")}}:</div>
                <v-enterprise-chip v-if="controlInfo.FactExecuterId == '00000000-0000-0000-0000-000000000000'" :id="controlInfo.FactExecuterId" :name="controlInfo.FactExecuter" />
                <v-workplace-chip v-else :id="controlInfo.FactExecuterId" :name="controlInfo.FactExecuter" :main="true" />
            </div>            
            
        </v-card-text>
    </v-card>
</template>

<script>
import sys from '@/services/system'
import { mapGetters } from 'vuex';

export default {
    classes: ["mdwi-gr-grey", "mdwi-gr-green", "mdwi-gr-aqua" ],
    props: 
    {
        controlInfo: 
        {
            type: Object,
            default: null
        }
    },
    methods: {
        getClassByDocumentStatus() {
            var statusName = Object.keys(this.avaibleStatuses).find(key => this.avaibleStatuses[key] === this.documentStatus);
            switch (statusName)
            {
                case "done":
                    return "mdwi-gr-green"; //зеленый
                
                case "doneExpired":
                    return "mdwi-gr-green-bordered"; // зеленый с красной чертой
                
                case "consideration":
                case "work":
                    return "mdwi-gr-yellow"; //желтый

                case "today":
                    return "mdwi-gr-orange"; //оранжевый
                
                case "considerationExpired":
                case "expired":
                    return "mdwi-gr-aqua"; // красный

                case "takeOffControl":
                case "onExecutionCheck":
                    return "mdwi-gr-purple"; // фиол

                default:
                    return "mdwi-gr-grey";
            }
        },
        getStatusIndex() {
            if (this.controlInfo.MainExecuteExist && this.controlInfo.ControlType != 100)
                return this.avaibleStatuses['done'];
            
            else if (this.controlInfo.InnerControlDate && this.controlInfo.ControlType != 100) {

                let innerControlDate = this.$moment(this.controlInfo.InnerControlDate);
                let currentTime = this.$moment(this.controlInfo.CurrentTime);

                if (!!innerControlDate && innerControlDate.isValid() && !!currentTime && currentTime.isValid()) {
                    if (Math.round((currentTime.toDate() - innerControlDate)/(1000*60*60*24)) > 1)
                        return this.avaibleStatuses['expired'];
                }
            }

            return this.avaibleStatuses['work'];
        }
    },
    computed:
    {
        ...mapGetters('global/actionsource', { isCommon: 'isDataSourceCommon', dsType: 'getDataSourceType' }),
        configuration(){
            return process.env.VUE_APP_CONFIGURATION;
        },
        avaibleStatuses() {
            return this.$store.getters['documents/references/GetAvaibleStatuses'];
        },
        isInnerControlDateToday()
        {
            let innerControlDate = this.$moment(this.controlInfo.InnerControlDate);
            let currentTime = this.$moment(this.controlInfo.CurrentTime);

            if (!!innerControlDate && innerControlDate.isValid() && !!currentTime && currentTime.isValid()) 
            {
                return innerControlDate.isSame(currentTime, 'day');
            }

            return false;
        },
        isOuterControlDateToday()
        {
            let outerControlDate = this.$moment(this.controlInfo.OuterControlDate);
            let currentTime = this.$moment(this.controlInfo.CurrentTime);

            if (!!outerControlDate && outerControlDate.isValid() && !!currentTime && currentTime.isValid()) 
            {
                return outerControlDate.isSame(currentTime, 'day');
            }

            return false;
        },
        documentStatus() {
            if (this.dsType === 'InnerDocument')
                return this.getStatusIndex();
            let ds = '';
            if (this.dsType === 'ProtocolDocument' && this.controlInfo.CalcStatus)
                ds = this.controlInfo.CalcStatus;
            else 
                ds = this.$helpers.calculateDocumentStatus(this.controlInfo.ControlType, this.controlInfo.InnerControlDate, this.controlInfo.ExecutionDate, this.controlInfo.FactExecuterId != sys.guidEmpty());

            return this.avaibleStatuses[ds];        
        },
    },
    filters: 
    {
        formattedDate: (value) => {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    mounted() {
        //console.log(this.controlInfo);
    }
}
</script>